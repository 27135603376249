import { useState } from "react";
import DeleteConfirmation from "../modals/DeleteConfirmation";

function Award({ logo, title, description, handleDelete, id, onEdit }) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDeleteClick = () => {
        // console.log("ID in reference compo: ", id)
        // handleDelete(id)
        setShowDeleteModal(true)
      };
  return (
    <div
      className="flex flex-col gap-2 bg-white rounded-md p-3 product-card-shadow w-full"
    >
      {/* DETAILS */}
      <div className="flex flex-col gap-2 text-center">
        {/* Image */}
        <div className="">
          <img
            src={logo ? logo : "/assets/image-not-found.png"}
            alt={title}
            className="h-[61px] w-[62px] rounded-full m-auto"
          />
        </div>

        {/* Name and description */}
        <div className="flex flex-col mt-2">
          <div className="text-lg font-semibold overflow-hidden text-ellipsis whitespace-nowrap capitalize">
            {title ? title : ""}
          </div>
          <div className="text-grey-9 font-light overflow-hidden text-ellipsis whitespace-nowrap capitalize">
            {description ? description : ""}
          </div>
        </div>
      </div>
      {/* ACTION BUTTONS */}
      <div className="flex justify-center gap-2">
        <div className="">
          <button
            className=""
            onClick={() => onEdit(true, id)}
          >
           <img src="/assets/edit.svg" />
          </button>
        </div>
        <div
          role="button"
          className=""
         
        >
           <button
            className=""
            onClick={handleDeleteClick}
          >
              <img src="assets/trash.svg" alt="delete" />
          </button>
        
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmation
          setShowModal={setShowDeleteModal}
          deleteHandler={() => handleDelete(id)}
        />
      )}
    </div>
  );
}

export default Award;
