//import dependencies
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//import components
import CompanyProfile from './components/CompanyProfile';
import HomePage from './components/HomePage'


//import css files
import './App.css';
import Header from './components/general/NewHeader';
import Footer from './components/general/NewFooter';
import { useContext, useState } from 'react';
import RegistrationForm from './components/subPages/RegistrationForm';
import TestRoute from './components/TestRoute';
import Dummy from './components/Dummy';
import Loader from './components/general/Loader';
import { StateContext } from './Context/StateContext';
import Home from './components/subPages/products/Demo';
import Demo from './components/subPages/products/Demo';
import LandngPage from './components/LandngPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoutes from './utils/protectedRoutes';
import LinkedInAuth from './components/subPages/LinkedInAuth';
import DataNotFound from './components/general/DataNotFound';
import PageNotFound from './components/general/PageNotFound';
import NewsLoader from './components/general/NewsLoader';
import PrivacyLoader from './components/general/PrivacyLoader';
import ResetPassword from './components/general/ResetPassword';


function App() {

  const { loading, newsLoading, privacyLoading } = useContext(StateContext)

  return (

    <div className='flex flex-col'>
     
      {loading && <Loader/>}
      {newsLoading && <NewsLoader/>}
      {privacyLoading && <PrivacyLoader/>}
      {/* <ToastContainer/> */}
      <BrowserRouter>
      
      <Routes>
        

        <Route path='/' element={<HomePage/>} />
        <Route path='/register' element={<RegistrationForm/>} />
        <Route path='/resetPassword' element={<ResetPassword/>} />
        <Route path='/testRoute' element={<TestRoute/>} />
        <Route path='/landingPage' element={<LandngPage/>} />
        <Route path='/linkedInAuth' element={<LinkedInAuth/>} />
        <Route path='/dummy' element={<Dummy/>} />
        {/* <Route path='/demo' element={<Demo/>} /> */}
        {/* <Route path='/homepage' element={<HomePage/>} /> */}
        {/* <Route path='/company' element={<CompanyProfile/>} /> */}
        {/* <Route path='/dummy' element={<Dummy/>} /> */}

        <Route path="/company" element={<ProtectedRoutes component={<CompanyProfile />}/>} />
        <Route path="/*" element={<PageNotFound/>} />
        <Route path="/page-not-found" element={<PageNotFound/>} />


      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
