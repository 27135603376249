import { useContext, useEffect, useState } from "react";
import InputField from "../../general/NewInputField";
import { getMethod, postFormMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { StateContext } from "../../../Context/StateContext";
import ImageUpload from "../../general/ImageUpload";
import { SendPeopleNotification } from "../../../utils/notificationApis";
import NotificationConfirmation from "../../general/modals/NotificationConfirmation";
import { toCamelCase } from "../../../utils/stringNumberFormat";

function PeopleAdd({ onBackClick }) {
  const [selectedValue, setSelectedValue] = useState(null);
  const [peopleTypes, setPeopleTypes] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const [peopleMemberName, setPeopleMemberName] = useState("")

  // ADVISORS : state vaiables , functions
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [bio, setBio] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const { setLoading } = useContext(StateContext);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // EXECUTIVES : state vaiables , functions
  const [executiveName, setExecutiveName] = useState("");
  const [executiveTitle, setExecutiveTitle] = useState("");
  const [executiveLinkedInUrl, setExecutiveLinkedInUrl] = useState("");
  const [selectedExecutiveFile, setSelectedExecutiveFile] = useState(null);

  const handleExecutiveFileChange = (event) => {
    setSelectedExecutiveFile(event.target.files[0]);
  };
  // INVESTORS : state vaiables , functions
  const [investorName, setInvestorName] = useState("");
  const [selectedInvestorFile, setSelectedInvestorFile] = useState(null);

  const handleInvestorsFileChange = (event) => {
    setSelectedInvestorFile(event.target.files[0]);
  };

  // EXECUTIVES : state vaiables , functions
  const [memberName, setMemberName] = useState("");
  const [memberTitle, setMemberTitle] = useState("");
  const [memberLinkedInUrl, setMemberLinkedInUrl] = useState("");
  const [selectedMemberFile, setSelectedMemberFile] = useState(null);

  const handleMembersFileChange = (event) => {
    setSelectedMemberFile(event.target.files[0]);
  };

  // Common functions

  function fetchSelectedValue(value) {
    console.log("SELECTED VALUE : ", value);
    setSelectedValue(value);
  }

  function validateUrl(url, type) {
    try {
      let validate = new URL(url);
      const isLinkedIn =
        validate.hostname.includes("linkedin.com") &&
        validate.pathname.startsWith("/in/");

      if (!isLinkedIn) {
        throw new Error();
      }

      return true;
    } catch (error) {
      throw new Error(`${type} LinkedIn url not valid!`);
    }
  }

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      if (
        (selectedValue === "Advisors" && !selectedFile) ||
        (selectedValue === "Executive Leadership" && !selectedExecutiveFile) ||
        (selectedValue === "Primary Investors" && !selectedInvestorFile) ||
        (selectedValue === "Board Members" && !selectedMemberFile)
      ) {
        toastError("Image is required");
      } else {
        // checking of the values changed in field

        var formdata = new FormData();
        if (selectedValue === "Advisors") {
          validateUrl(linkedInUrl, "Advisor");
          setPeopleMemberName(name);
          setLoading(true);
          formdata.append("name", name);
          formdata.append("peopleTypeId", 4);
          formdata.append("title", title);
          formdata.append("linkedinUrl", linkedInUrl);
          // formdata.append("bio", bio);
          formdata.append("profileImage", selectedFile);
        } else {
          if (selectedValue === "Executive Leadership") {
            validateUrl(executiveLinkedInUrl, "Executive");
            setPeopleMemberName(executiveName);
            setLoading(true);
            formdata.append("name", executiveName);
            formdata.append("peopleTypeId", 1);
            formdata.append("title", executiveTitle);
            formdata.append("linkedinUrl", executiveLinkedInUrl);
            formdata.append("profileImage", selectedExecutiveFile);
          }

          if (selectedValue === "Primary Investors") {
            setPeopleMemberName(investorName);
            setLoading(true);
            formdata.append("name", investorName);
            formdata.append("peopleTypeId", 2);
            formdata.append("profileImage", selectedInvestorFile);
          }
          if (selectedValue === "Board Members") {
            validateUrl(memberLinkedInUrl, "Board Member");
            setPeopleMemberName(memberName);
            setLoading(true);
            formdata.append("name", memberName);
            formdata.append("peopleTypeId", 3);
            formdata.append("title", memberTitle);
            formdata.append("linkedinUrl", memberLinkedInUrl);
            formdata.append("profileImage", selectedMemberFile);
          }
        }

        postFormMethod(`people/addPeople`, formdata)
          .then((res) => {
            setLoading(false);
            if (res.status === true) {
              console.log("RESPONSE : ", res.data);
              toastSuccess(res.message);
              setNotificationModal(true);
              // onBackClick();
            } else {
              toastError(res.message);
              console.log("res.message: ", res.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }
    } catch (error) {
      setLoading(false);

      toastError(error.message);
    }
  };

  const getPeopleTypesApiCall = () => {
    setLoading(true);
    getMethod(`people/getPeopleTypes`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          const peopleTypes = res?.data.map((item, index) => {
            return item.type;
          });
          setPeopleTypes(peopleTypes);

          console.log("RES: ", res);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  useEffect(() => {
    getPeopleTypesApiCall();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <form
      className="p-5 lg:p-8 category-content-wrapper-div"
      onSubmit={submitHandler}
    >
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Add people</div>
              <div className="text-grey-2">Add people along with details</div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" type="submit">
              Add
            </button>
          </div>
        </div>
      </div>

      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* Choose type dropdown */}
        <div className="form-outline-wrapper-div p-8 gap-8">
          <InputField
            label="Choose type"
            placeholder={"select type"}
            width="full"
            isDropdown={true}
            options={peopleTypes}
            dropdownClasses={"text-xl"}
            getSelectedValue={fetchSelectedValue}
          />
        </div>
      </div>

      {/* FORM FIELDS ACCORDING TO SELECTED OPTION */}

      {/* 1) Advisors */}

      {selectedValue === "Advisors" ? (
        <div className="form-outline-wrapper-div p-8 gap-8">
          <ImageUpload
            thumbnail={selectedFile}
            setThumbnail={setSelectedFile}
            label={"Advisor's Pic"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Advisor Name"
              placeholder="Advisor Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              optional={false}
              width={"w-full"}
            />
            <InputField
              label="Title"
              placeholder="Add title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              optional={false}
              width={"w-full"}
            />
            <InputField
              label="LinkedIn URL"
              placeholder="Add linkedin profile URL"
              value={linkedInUrl}
              onChange={(e) => setLinkedInUrl(e.target.value)}
              optional={false}
              width={"w-full"}
            />
          </div>
        </div>
      ) : selectedValue === "Executive Leadership" ? (
        <div className="form-outline-wrapper-div p-8 gap-8">
          <ImageUpload
            thumbnail={selectedExecutiveFile}
            setThumbnail={setSelectedExecutiveFile}
            label={"Executive's Pic"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Executive Name"
              placeholder="Executive Name"
              value={executiveName}
              onChange={(e) => setExecutiveName(e.target.value)}
              width={"w-full"}
            />
            <InputField
              label="Title"
              placeholder="Add title"
              value={executiveTitle}
              onChange={(e) => setExecutiveTitle(e.target.value)}
              width={"w-full"}
            />
            <InputField
              label="LinkedIn URL"
              placeholder="Add linkedin profile url"
              value={executiveLinkedInUrl}
              onChange={(e) => setExecutiveLinkedInUrl(e.target.value)}
              width={"w-full"}
            />
          </div>
        </div>
      ) : selectedValue === "Primary Investors" ? (
        <div className="form-outline-wrapper-div p-8 gap-8">
          <ImageUpload
            thumbnail={selectedInvestorFile}
            setThumbnail={setSelectedInvestorFile}
            label={"Company Logo"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Investor Name or Investing Company Name"
              placeholder="Investor Name"
              value={investorName}
              onChange={(e) => setInvestorName(e.target.value)}
              optional={false}
              width={"w-full"}
            />
          </div>
        </div>
      ) : selectedValue === "Board Members" ? (
        <div className="form-outline-wrapper-div p-8 gap-8">
          <ImageUpload
            thumbnail={selectedMemberFile}
            setThumbnail={setSelectedMemberFile}
            label={"Member's Pic"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Member Name"
              placeholder="Member Name"
              value={memberName}
              onChange={(e) => setMemberName(e.target.value)}
              optional={false}
              width={"w-full"}
            />
            <InputField
              label="Title"
              placeholder="title"
              value={memberTitle}
              onChange={(e) => setMemberTitle(e.target.value)}
              optional={false}
              width={"w-full"}
            />
            <InputField
              label="LinkedIn URL"
              placeholder="LinkedIn URL"
              value={memberLinkedInUrl}
              onChange={(e) => setMemberLinkedInUrl(e.target.value)}
              optional={false}
              width={"w-full"}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex justify-end w-full lg:w-2/12">
          <button className="btn-sea-green text-xl w-full" type="submit">
            Add
          </button>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </form>
    {notificationModal && (
        <NotificationConfirmation
          yesHandler = {() => {
            SendPeopleNotification(toCamelCase(selectedValue),peopleMemberName);
            setNotificationModal(false);
            onBackClick();
          }
          }
          noHandler = {() => {
            setNotificationModal(false);
            onBackClick();
          }
          }
          keyword={"staff"}
        />
      )}
    </>
  );
}

export default PeopleAdd;
