import { useContext, useEffect, useState } from "react";
import { toastError, toastSuccess, toastWarn } from "../../../utils/Toasters";
import { getMethod, postMethod } from "../../../utils/apiMethod";
import InputField from "../../general/NewInputField";
import { StateContext } from "../../../Context/StateContext";
import { FormatNumber } from "../../../utils/stringNumberFormat";
import { SendMetricsNotification } from "../../../utils/notificationApis";
import NotificationCheckbox from "../../general/modals/NotificationCheckbox";
function MetricsEdit({ onBackClick }) {
  const { setLoading } = useContext(StateContext);
  const [notificationModal, setNotificationModal] = useState({
    list: [],
    show: false,
  });

  const [metricsResponse, setMetricsResponse] = useState({});
  const [metricDetails, setMetricDetails] = useState({
    totalEmployees: 0,
    totalCustomers: 0,
    clientRetentionPercent: "0",
    // npcScore:0,
  });

  const fetchEmployees = (value) => {
    const updatedMetricDetails = { ...metricDetails, totalEmployees: value };
    setMetricDetails(updatedMetricDetails);
  };
  const fetchCustomers = (value) => {
    console.log("CUSTOMERS: ", value);
    const updatedMetricDetails = { ...metricDetails, totalCustomers: value };
    setMetricDetails(updatedMetricDetails);
  };

  const setClientRetention = (e) => {
    let value = e.target.value;

    // Remove non-numeric characters except the decimal point
    value = value.replace(/[^\d.]/g, "");

    // Ensure the value does not exceed 100
    if (parseFloat(value) > 100) {
      value = "";
      toastWarn("Client Retention must be between 0 to 100"); // Cap the value at 100
    }

    // Format the number with commas if needed
    const formattedValue = FormatNumber(value);

    const updatedMetricDetails = {
      ...metricDetails,
      clientRetentionPercent: value, // Update the value, not formatted for % field
    };

    setMetricDetails(updatedMetricDetails);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("clientRetentionPercent: ", metricDetails);
    const raw = JSON.stringify({
      totalEmployees: metricDetails?.totalEmployees,
      totalCustomers: metricDetails?.totalCustomers,
      clientRetentionPercent: metricDetails?.clientRetentionPercent,
      // npcScore: metricDetails.npcScore,
    });

    getMethod(`metric/getMyMetricInformation`)
      .then((res) => {
        if (res?.exists) {
          // update
          let updateRaw = {};
          if (
            metricDetails?.totalEmployees !== metricsResponse?.totalEmployees
          ) {
            updateRaw = {
              ...updateRaw,
              totalEmployees: metricDetails?.totalEmployees,
            };
          }
          if (
            metricDetails?.totalCustomers !== metricsResponse?.totalCustomers
          ) {
            updateRaw = {
              ...updateRaw,
              totalCustomers: metricDetails?.totalCustomers,
            };
          }
          if (
            metricDetails?.clientRetentionPercent !==
            metricsResponse?.clientRetentionPercent
          ) {
            updateRaw = {
              ...updateRaw,
              clientRetentionPercent: metricDetails?.clientRetentionPercent,
            };
          }
          updateRaw = JSON.stringify(updateRaw);

          if (Object.keys(JSON.parse(updateRaw))?.length > 0) {
            postMethod(`metric/updateMetric`, updateRaw)
              .then((res) => {
                setLoading(false);
                if (res.status === true) {
                  toastSuccess(res.message);
                  setNotificationModal({
                    list: Object.keys(JSON.parse(updateRaw)),
                    show: true,
                  });
                }
              })
              .catch((error) => {
                setLoading(false);
                toastError(error.message);
                console.log("error.message: ", error.message);
              });
          } else {
            onBackClick();
          }
        } else {
          // add
          postMethod(`metric/addMetric`, raw)
            .then((res) => {
              setLoading(false);
              if (res.status == true) {
                toastSuccess(res.message);
                // onBackClick();
                setNotificationModal({
                  list: Object.keys(JSON.parse(raw)),
                  show: true,
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              toastError(error.message);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    getMethod(`metric/getMyMetricInformation`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setMetricsResponse(res?.data);
          console.log("Response metrics : ", res.data);
          const updatedMetricsDetails = {
            ...metricDetails,
            totalEmployees: res.data?.totalEmployees || 0,
            totalCustomers: res.data?.totalCustomers || 0,
            clientRetentionPercent: res.data?.clientRetentionPercent || "0",
            // npcScore: res.data?.npcScore,
          };
          setMetricDetails(updatedMetricsDetails);
        }
      })
      .catch((error) => {
        setLoading(false);
        // toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <form
      className="p-5 lg:p-8 category-content-wrapper-div"
      onSubmit={submitHandler}
    >
      {/* Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img
                src="assets/arrow-back-circle.svg"
                alt="arrow-back"
                className="cursor-pointer"
              />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Edit Metrics</div>
              <div className="text-grey-2">Add metrics details</div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" type="submit">
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="rounded-xl border border-grey-6 p-8 flex flex-col gap-8">
        {/* Input fields */}
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Total Employees"
              placeholder="Total Employees"
              value={metricDetails?.totalEmployees}
              isDropdown={true}
              optional={false}
              getSelectedValue={fetchEmployees}
              dropdownClasses={"uppercase text-sm"}
              width={"full"}
              options={[
                "0-10",
                "10-25",
                "25-50",
                "50-100",
                "100-200",
                "200-500",
                "500-1000",
                "1000-2000",
                "2000-5000",
                "5000-10000",
                "10000+",
              ]}
            />

            <InputField
              label="Total Customers"
              placeholder="Total Customers"
              optional={false}
              width={"full"}
              value={metricDetails?.totalCustomers}
              isDropdown={true}
              getSelectedValue={fetchCustomers}
              dropdownClasses={"uppercase text-sm"}
              width={"full"}
              options={[
                "0-10",
                "10-25",
                "25-50",
                "50-100",
                "100-200",
                "200-500",
                "500-1000",
                "1000-2000",
                "2000-5000",
                "5000+",
              ]}
            />

            <div className={`flex flex-col gap-4 w-full`}>
              <div className="font-light">
                Client Retention % <span className="text-orange-1">*</span>
                <span className="text-grey-12">
                  &nbsp;( max 07 characters )
                </span>
              </div>
              <div
                className={`flex items-center rounded-lg border border-grey-7 h-12 `}
              >
                <input
                  className="focus:border-transparent outline-none mx-5 w-full h-full"
                  placeholder="Add Retention %"
                  value={metricDetails?.clientRetentionPercent}
                  required={true}
                  maxLength={
                    metricDetails?.clientRetentionPercent?.includes(".") ? 5 : 3
                  }
                  onChange={setClientRetention}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex justify-end w-full lg:w-2/12">
          <button className="btn-sea-green text-xl w-full" type="submit">
            Save
          </button>
        </div>
      </div>

      {notificationModal?.show && (
        <NotificationCheckbox
          list={notificationModal?.list}
          message="Here's a summary of your recent metrics updates. Select which changes you'd like to notify your followers about :"
          onBackClick={onBackClick}
          setNotificationModal={setNotificationModal}
          notificationHandler={SendMetricsNotification}
        />
      )}
    </form>
  );
}

export default MetricsEdit;
