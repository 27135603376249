import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../Context/StateContext";
import { getMethod } from "../../../utils/apiMethod";
import { camelToTitleCase, toCamelCase } from "../../../utils/stringNumberFormat";
import { toastError } from "../../../utils/Toasters";
import LocalLoader from "../../general/LocalLoader";

function CompetitiveBenchmark() {
  const [data, setData] = useState([]);
  const [rank, setRank] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [duration, setDuration] = useState("followers");
  const { topEngagedSection } = useContext(StateContext);

  useEffect(() => {
    setLoading(true);

    getMethod(`analytics/topCompanies?by=${duration}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setData(res?.data);
          setRank(res?.rank);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, [duration]);
  return (
    <div className="flex flex-col md:flex-row gap-5">
      {/* Scrollable component */}
      <div className="flex flex-col rounded-xl box-shadow4 w-full md:w-3/5 p-1 ps-3 bg-white">
        <div className="md:flex justify-between p-5">
          <div className="font-bold">Competitive Benchmark</div>

          {/* Sort Dropdown */}
          {/* Sort Dropdown */}
          <div className="flex items-center mb-5">
            <p>Sort by: </p>
            <div
              className="ml-2 p-2 border border-grey-7 rounded-md cursor-pointer flex items-center shadow-sm relative"
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              {camelToTitleCase(duration)}
              <svg width="16" height="16" className="ml-2" viewBox="0 0 23 22">
                {/* Dropdown Arrow */}
                <path
                  d="M11.8 14.4L5.6 8.2C5.3 7.9 5.1 7.4 5.4 7.1C5.7 6.8 6.2 7 6.5 7.4L12 12.9L17.5 7.4C17.8 7 18.3 6.8 18.6 7.1C18.9 7.4 18.7 7.9 18.4 8.2L12.2 14.4C12 14.6 11.8 14.6 11.8 14.4Z"
                  fill="#222222"
                />
              </svg>
              {/* Dropdown */}
              {openDropdown && (
                <div className="px-1 py-2 border border-grey-7 bg-white rounded-md cursor-pointer flex flex-col items-center shadow-sm absolute gap-2 -bottom-20 gap-2 w-28 z-30">
                  <div onClick={() => setDuration("followers")}>Followers</div>
                  <div onClick={() => setDuration("views")}>Views</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="overflow-auto max-h-96">
          {loading ? (
            <LocalLoader />
          ) : (
            <table className="w-full border-separate">
              <tbody>
                {data?.map((item, index) => (
                  <tr key={item?.id} className="bg-white">
                    <td className="py-3 border-b border-grey-7">{index + 1}</td>
                    <td className="py-3 border-b border-grey-7 px-2 flex gap-2 items-center">
                      <div className="w-12 h-12 rounded-full md:mr-3">
                        <img
                          className="w-full h-full rounded-full object-fill"
                          src={
                            item?.thumbnailUrl
                              ? item?.thumbnailUrl
                              : "/assets/image-not-found.png"
                          }
                        />
                      </div>
                      <div>{item?.name}</div>
                    </td>
                    <td className="py-3 border-b border-grey-7 px-2">
                      <span className="bg-light-sea-green border border-sea-green text-sm p-2 md:p-4 rounded-full">
                        {item?.followerCount} {item?.followerCount > 1 ? "Followers":"Follower"}
                      </span>
                    </td>
                    <td className="py-3 border-b border-grey-7 px-2">
                      <span className="bg-blue-light border border-aqua-1 text-sm p-2 md:p-4 rounded-full">
                        {item?.views} {item?.views > 1 ? "Views":"View"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-3 w-full md:w-2/5">
        <div className="rounded-xl bg-light-sea-green border border-sea-green p-10 w-full box-shadow3">
          <div className="text-3xl text-dark-blue-1 capitalize">
            {topEngagedSection}
          </div>
          <div className="font-bold text-grey-10 text-lg">
            Most Viewed Section
          </div>
        </div>
        <div className="rounded-xl bg-light-sea-green border border-sea-green p-10 w-full box-shadow3">
          <div className="text-3xl text-dark-blue-1">#{rank}</div>
          <div className="font-bold text-grey-10 text-lg">
            Ranking (<span className="capitalize">{duration}</span>) 
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default CompetitiveBenchmark;
